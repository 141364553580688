<template>
  <div>
    <!-- <b-row class="mt-2">
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="10" />
      <b-col cols="3" v-for="(metric, ix) in clientMetrics" :key="ix">
        <rm-metric-card growth :metric="metric" />
      </b-col>
    </b-row> -->
    <b-row class="mt-5">
      <b-col cols="12">
        <rm-commissions-table />
      </b-col>
    </b-row>
    <!-- <div class="d-flex">
			<rm-new-clients-chart />
			<rm-country-chart class="mx-4" />
			<rm-age-chart />
		</div>
		<b-row class="mt-5">
			<b-col cols="12">
				<rm-clients-table />
			</b-col>
			<b-col cols="4"> </b-col>
			<b-col cols="4"> </b-col>
		</b-row> -->
  </div>
</template>

<script>
// import RmMetricCard from "@/components/global/RmMetricCard2.vue";
// import RmInsights from "./components/RmInsights.vue";
// import RmPlotSales from "./components/RmPlotSales.vue";
// import RmRatingCard from "./components/RmRatingsCard.vue";
import RmCommissionsTable from "./components/RmCommissionsTable.vue";
export default {
  components: {
    // RmMetricCard,
    // RmInsights,
    // RmPlotSales,
    // RmRatingCard,
    RmCommissionsTable,
  },
  data: () => ({
    metricOptions: ["All Time"],
    clientMetrics: [
      {
        name: "Total Commissions",
        amount: "5000",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Completed Payouts",
        amount: "3000",
        growth: +15,
        icon: "active-clients",
      },
      {
        name: "Unapproved Payouts",
        amount: "1000",
        growth: +100,
        icon: "inactive-clients",
      },
      {
        name: "Approved Payouts",
        amount: "1000",
        growth: -22.5,
        icon: "prospective-clients",
      },
    ],
  }),
};
</script>
